<template>
	<component
		:is="icon"
		v-bind="$attrs"
		:height="hw"
		:width="hw"
		:fill="color"
	></component>
</template>

<script setup>
import { defineAsyncComponent, computed } from "vue";

const components = {
	TNum9Outline: defineAsyncComponent(() => import("./child/TNum9Outline.vue")),
	TNum9Filled: defineAsyncComponent(() => import("./child/TNum9Filled.vue")),
	TNum9: defineAsyncComponent(() => import("./child/TNum9.vue")),
	TNum8Outline: defineAsyncComponent(() => import("./child/TNum8Outline.vue")),
	TNum8Filled: defineAsyncComponent(() => import("./child/TNum8Filled.vue")),
	TNum8: defineAsyncComponent(() => import("./child/TNum8.vue")),
	TNum7Outline: defineAsyncComponent(() => import("./child/TNum7Outline.vue")),
	TNum7Filled: defineAsyncComponent(() => import("./child/TNum7Filled.vue")),
	TNum7: defineAsyncComponent(() => import("./child/TNum7.vue")),
	TNum6Outline: defineAsyncComponent(() => import("./child/TNum6Outline.vue")),
	TNum6Filled: defineAsyncComponent(() => import("./child/TNum6Filled.vue")),
	TNum6: defineAsyncComponent(() => import("./child/TNum6.vue")),
	TNum5Outline: defineAsyncComponent(() => import("./child/TNum5Outline.vue")),
	TNum5Filled: defineAsyncComponent(() => import("./child/TNum5Filled.vue")),
	TNum5: defineAsyncComponent(() => import("./child/TNum5.vue")),
	TNum4Outline: defineAsyncComponent(() => import("./child/TNum4Outline.vue")),
	TNum4Filled: defineAsyncComponent(() => import("./child/TNum4Filled.vue")),
	TNum4: defineAsyncComponent(() => import("./child/TNum4.vue")),
	TNum3Outline: defineAsyncComponent(() => import("./child/TNum3Outline.vue")),
	TNum3Filled: defineAsyncComponent(() => import("./child/TNum3Filled.vue")),
	TNum3: defineAsyncComponent(() => import("./child/TNum3.vue")),
	TNum2Outline: defineAsyncComponent(() => import("./child/TNum2Outline.vue")),
	TNum2Filled: defineAsyncComponent(() => import("./child/TNum2Filled.vue")),
	TNum2: defineAsyncComponent(() => import("./child/TNum2.vue")),
	TNum1Outline: defineAsyncComponent(() => import("./child/TNum1Outline.vue")),
	TNum1Filled: defineAsyncComponent(() => import("./child/TNum1Filled.vue")),
	TNum1: defineAsyncComponent(() => import("./child/TNum1.vue")),
	TNum0Outline: defineAsyncComponent(() => import("./child/TNum0Outline.vue")),
	TNum0Filled: defineAsyncComponent(() => import("./child/TNum0Filled.vue")),
	TNum0: defineAsyncComponent(() => import("./child/TNum0.vue")),
	TCart: defineAsyncComponent(() => import("./child/TCart.vue")),
	TCartNewItem: defineAsyncComponent(() => import("./child/TCartNewItem.vue")),
	TGift: defineAsyncComponent(() => import("./child/TGift.vue")),
	TCreditCard: defineAsyncComponent(() => import("./child/TCreditCard.vue")),
	TKroner: defineAsyncComponent(() => import("./child/TKroner.vue")),
	TBills: defineAsyncComponent(() => import("./child/TBills.vue")),
	TCoins: defineAsyncComponent(() => import("./child/TCoins.vue")),
	TGraph: defineAsyncComponent(() => import("./child/TGraph.vue")),
	TDelivery: defineAsyncComponent(() => import("./child/TDelivery.vue")),
	TPieChart: defineAsyncComponent(() => import("./child/TPieChart.vue")),
	TQr: defineAsyncComponent(() => import("./child/TQr.vue")),
	TDeliveryExpress: defineAsyncComponent(() => import("./child/TDeliveryExpress.vue")),
	TPerson: defineAsyncComponent(() => import("./child/TPerson.vue")),
	TBusiness: defineAsyncComponent(() => import("./child/TBusiness.vue")),
	TFamily: defineAsyncComponent(() => import("./child/TFamily.vue")),
	TChild1: defineAsyncComponent(() => import("./child/TChild1.vue")),
	TChild2: defineAsyncComponent(() => import("./child/TChild2.vue")),
	TCustomerService: defineAsyncComponent(() => import("./child/TCustomerService.vue")),
	TEmployee: defineAsyncComponent(() => import("./child/TEmployee.vue")),
	TPeople: defineAsyncComponent(() => import("./child/TPeople.vue")),
	TAccount: defineAsyncComponent(() => import("./child/TAccount.vue")),
	TFaceId: defineAsyncComponent(() => import("./child/TFaceId.vue")),
	TFingerprint: defineAsyncComponent(() => import("./child/TFingerprint.vue")),
	TMicrosoftOffice: defineAsyncComponent(() => import("./child/TMicrosoftOffice.vue")),
	TTwitterLegacy: defineAsyncComponent(() => import("./child/TTwitterLegacy.vue")),
	TYouTube: defineAsyncComponent(() => import("./child/TYouTube.vue")),
	TFacebook: defineAsyncComponent(() => import("./child/TFacebook.vue")),
	TLinkedIn: defineAsyncComponent(() => import("./child/TLinkedIn.vue")),
	TInstagram: defineAsyncComponent(() => import("./child/TInstagram.vue")),
	TBankId: defineAsyncComponent(() => import("./child/TBankId.vue")),
	TChromecastOff: defineAsyncComponent(() => import("./child/TChromecastOff.vue")),
	TChromecastOn: defineAsyncComponent(() => import("./child/TChromecastOn.vue")),
	TDolbyDigital: defineAsyncComponent(() => import("./child/TDolbyDigital.vue")),
	TAppleAirplay: defineAsyncComponent(() => import("./child/TAppleAirplay.vue")),
	TX: defineAsyncComponent(() => import("./child/TX.vue")),
	TWeeklyArchive: defineAsyncComponent(() => import("./child/TWeeklyArchive.vue")),
	TChevronRight: defineAsyncComponent(() => import("./child/TChevronRight.vue")),
	TChevronLeft: defineAsyncComponent(() => import("./child/TChevronLeft.vue")),
	TChevronUp: defineAsyncComponent(() => import("./child/TChevronUp.vue")),
	TChevronDown: defineAsyncComponent(() => import("./child/TChevronDown.vue")),
	TUp: defineAsyncComponent(() => import("./child/TUp.vue")),
	TDown: defineAsyncComponent(() => import("./child/TDown.vue")),
	TLeft: defineAsyncComponent(() => import("./child/TLeft.vue")),
	TRight: defineAsyncComponent(() => import("./child/TRight.vue")),
	TDiagonalDownRight: defineAsyncComponent(() => import("./child/TDiagonalDownRight.vue")),
	TDiagonalDownLeft: defineAsyncComponent(() => import("./child/TDiagonalDownLeft.vue")),
	TDiagonalUpRight: defineAsyncComponent(() => import("./child/TDiagonalUpRight.vue")),
	TDiagonalUpLeft: defineAsyncComponent(() => import("./child/TDiagonalUpLeft.vue")),
	TReload: defineAsyncComponent(() => import("./child/TReload.vue")),
	TRefresh: defineAsyncComponent(() => import("./child/TRefresh.vue")),
	TRecycle: defineAsyncComponent(() => import("./child/TRecycle.vue")),
	TExternalLink: defineAsyncComponent(() => import("./child/TExternalLink.vue")),
	TDownloadTray: defineAsyncComponent(() => import("./child/TDownloadTray.vue")),
	TUploadTray: defineAsyncComponent(() => import("./child/TUploadTray.vue")),
	TExpand: defineAsyncComponent(() => import("./child/TExpand.vue")),
	TCollapse: defineAsyncComponent(() => import("./child/TCollapse.vue")),
	TSubdirectoryLeft: defineAsyncComponent(() => import("./child/TSubdirectoryLeft.vue")),
	TSubdirectoryRight: defineAsyncComponent(() => import("./child/TSubdirectoryRight.vue")),
	TTrendingUp: defineAsyncComponent(() => import("./child/TTrendingUp.vue")),
	TTrendingDown: defineAsyncComponent(() => import("./child/TTrendingDown.vue")),
	TChevronsDown: defineAsyncComponent(() => import("./child/TChevronsDown.vue")),
	TChevronsLeft: defineAsyncComponent(() => import("./child/TChevronsLeft.vue")),
	TChevronsRight: defineAsyncComponent(() => import("./child/TChevronsRight.vue")),
	TChevronsUp: defineAsyncComponent(() => import("./child/TChevronsUp.vue")),
	TCtaRight: defineAsyncComponent(() => import("./child/TCtaRight.vue")),
	TCtaLeft: defineAsyncComponent(() => import("./child/TCtaLeft.vue")),
	TSort: defineAsyncComponent(() => import("./child/TSort.vue")),
	TSortDown: defineAsyncComponent(() => import("./child/TSortDown.vue")),
	TSortUp: defineAsyncComponent(() => import("./child/TSortUp.vue")),
	TSkipBack10s: defineAsyncComponent(() => import("./child/TSkipBack10s.vue")),
	TSkipForward10s: defineAsyncComponent(() => import("./child/TSkipForward10s.vue")),
	TSkipForward15s: defineAsyncComponent(() => import("./child/TSkipForward15s.vue")),
	TSkipBackward15s: defineAsyncComponent(() => import("./child/TSkipBackward15s.vue")),
	TSkipForward30s: defineAsyncComponent(() => import("./child/TSkipForward30s.vue")),
	TSkipBackward30s: defineAsyncComponent(() => import("./child/TSkipBackward30s.vue")),
	TSkipForward: defineAsyncComponent(() => import("./child/TSkipForward.vue")),
	TSortHorizontal: defineAsyncComponent(() => import("./child/TSortHorizontal.vue")),
	TContractScreen: defineAsyncComponent(() => import("./child/TContractScreen.vue")),
	TExpandScreen: defineAsyncComponent(() => import("./child/TExpandScreen.vue")),
	TContentSwitcher: defineAsyncComponent(() => import("./child/TContentSwitcher.vue")),
	TSeries: defineAsyncComponent(() => import("./child/TSeries.vue")),
	TMovies: defineAsyncComponent(() => import("./child/TMovies.vue")),
	TSport: defineAsyncComponent(() => import("./child/TSport.vue")),
	TTickets: defineAsyncComponent(() => import("./child/TTickets.vue")),
	TLive: defineAsyncComponent(() => import("./child/TLive.vue")),
	TMovieClip: defineAsyncComponent(() => import("./child/TMovieClip.vue")),
	TRemoveCircleOutline: defineAsyncComponent(() => import("./child/TRemoveCircleOutline.vue")),
	TRemoveCircleFilled: defineAsyncComponent(() => import("./child/TRemoveCircleFilled.vue")),
	TMinus: defineAsyncComponent(() => import("./child/TMinus.vue")),
	TMinusCircleOutline: defineAsyncComponent(() => import("./child/TMinusCircleOutline.vue")),
	TMinusCircleFilled: defineAsyncComponent(() => import("./child/TMinusCircleFilled.vue")),
	TRemove: defineAsyncComponent(() => import("./child/TRemove.vue")),
	TEyeOpen: defineAsyncComponent(() => import("./child/TEyeOpen.vue")),
	TEyeClosed: defineAsyncComponent(() => import("./child/TEyeClosed.vue")),
	TMenu: defineAsyncComponent(() => import("./child/TMenu.vue")),
	TLocation: defineAsyncComponent(() => import("./child/TLocation.vue")),
	TNotificationDot: defineAsyncComponent(() => import("./child/TNotificationDot.vue")),
	TNotificationNone: defineAsyncComponent(() => import("./child/TNotificationNone.vue")),
	TNearMeOff: defineAsyncComponent(() => import("./child/TNearMeOff.vue")),
	TNearMeOn: defineAsyncComponent(() => import("./child/TNearMeOn.vue")),
	TNotificationNew: defineAsyncComponent(() => import("./child/TNotificationNew.vue")),
	TBullet: defineAsyncComponent(() => import("./child/TBullet.vue")),
	TFilter: defineAsyncComponent(() => import("./child/TFilter.vue")),
	THelp: defineAsyncComponent(() => import("./child/THelp.vue")),
	TLockOutlineClosed: defineAsyncComponent(() => import("./child/TLockOutlineClosed.vue")),
	TLockOutlineOpen: defineAsyncComponent(() => import("./child/TLockOutlineOpen.vue")),
	TLockFilledClosed: defineAsyncComponent(() => import("./child/TLockFilledClosed.vue")),
	TLockFilledOpen: defineAsyncComponent(() => import("./child/TLockFilledOpen.vue")),
	THeartOutline: defineAsyncComponent(() => import("./child/THeartOutline.vue")),
	THeartFilled: defineAsyncComponent(() => import("./child/THeartFilled.vue")),
	TCalendar: defineAsyncComponent(() => import("./child/TCalendar.vue")),
	THome: defineAsyncComponent(() => import("./child/THome.vue")),
	TDragDots: defineAsyncComponent(() => import("./child/TDragDots.vue")),
	TBulletOutline: defineAsyncComponent(() => import("./child/TBulletOutline.vue")),
	TEmail: defineAsyncComponent(() => import("./child/TEmail.vue")),
	TAt: defineAsyncComponent(() => import("./child/TAt.vue")),
	TMessage: defineAsyncComponent(() => import("./child/TMessage.vue")),
	THistory: defineAsyncComponent(() => import("./child/THistory.vue")),
	TTimer: defineAsyncComponent(() => import("./child/TTimer.vue")),
	TStarOutline: defineAsyncComponent(() => import("./child/TStarOutline.vue")),
	TStarFilled: defineAsyncComponent(() => import("./child/TStarFilled.vue")),
	TLink: defineAsyncComponent(() => import("./child/TLink.vue")),
	TLink2: defineAsyncComponent(() => import("./child/TLink2.vue")),
	TVoicemail: defineAsyncComponent(() => import("./child/TVoicemail.vue")),
	TUnavailable: defineAsyncComponent(() => import("./child/TUnavailable.vue")),
	TTag: defineAsyncComponent(() => import("./child/TTag.vue")),
	TTagFilled: defineAsyncComponent(() => import("./child/TTagFilled.vue")),
	TSecurity: defineAsyncComponent(() => import("./child/TSecurity.vue")),
	TSecurityOn: defineAsyncComponent(() => import("./child/TSecurityOn.vue")),
	TSecurityOff: defineAsyncComponent(() => import("./child/TSecurityOff.vue")),
	TEyeOff: defineAsyncComponent(() => import("./child/TEyeOff.vue")),
	TClose: defineAsyncComponent(() => import("./child/TClose.vue")),
	TService: defineAsyncComponent(() => import("./child/TService.vue")),
	TPushPinOutline: defineAsyncComponent(() => import("./child/TPushPinOutline.vue")),
	TBranch: defineAsyncComponent(() => import("./child/TBranch.vue")),
	TShare: defineAsyncComponent(() => import("./child/TShare.vue")),
	TMegaphone: defineAsyncComponent(() => import("./child/TMegaphone.vue")),
	TBullhorn: defineAsyncComponent(() => import("./child/TBullhorn.vue")),
	TPushPinFilled: defineAsyncComponent(() => import("./child/TPushPinFilled.vue")),
	TExpandInputBox: defineAsyncComponent(() => import("./child/TExpandInputBox.vue")),
	TPlusCircleFilled: defineAsyncComponent(() => import("./child/TPlusCircleFilled.vue")),
	TPlusCircleOutline: defineAsyncComponent(() => import("./child/TPlusCircleOutline.vue")),
	TPlus: defineAsyncComponent(() => import("./child/TPlus.vue")),
	TSearch: defineAsyncComponent(() => import("./child/TSearch.vue")),
	TSpinner: defineAsyncComponent(() => import("./child/TSpinner.vue")),
	TTelenor: defineAsyncComponent(() => import("./child/TTelenor.vue")),
	TSettings: defineAsyncComponent(() => import("./child/TSettings.vue")),
	TQuestionCircleOutline: defineAsyncComponent(() => import("./child/TQuestionCircleOutline.vue")),
	TViewList: defineAsyncComponent(() => import("./child/TViewList.vue")),
	TViewHorizontalCard: defineAsyncComponent(() => import("./child/TViewHorizontalCard.vue")),
	TViewTable: defineAsyncComponent(() => import("./child/TViewTable.vue")),
	TViewCard: defineAsyncComponent(() => import("./child/TViewCard.vue")),
	TServices: defineAsyncComponent(() => import("./child/TServices.vue")),
	TTrashcan: defineAsyncComponent(() => import("./child/TTrashcan.vue")),
	TOverflowMenuHorizontal: defineAsyncComponent(() => import("./child/TOverflowMenuHorizontal.vue")),
	TOverflowMenuVertical: defineAsyncComponent(() => import("./child/TOverflowMenuVertical.vue")),
	TSubscription: defineAsyncComponent(() => import("./child/TSubscription.vue")),
	TQuestion: defineAsyncComponent(() => import("./child/TQuestion.vue")),
	TQuestionCircleFilled: defineAsyncComponent(() => import("./child/TQuestionCircleFilled.vue")),
	TEdit: defineAsyncComponent(() => import("./child/TEdit.vue")),
	TMusic: defineAsyncComponent(() => import("./child/TMusic.vue")),
	TClock: defineAsyncComponent(() => import("./child/TClock.vue")),
	TGlobe: defineAsyncComponent(() => import("./child/TGlobe.vue")),
	TChart: defineAsyncComponent(() => import("./child/TChart.vue")),
	TSettingsComplex: defineAsyncComponent(() => import("./child/TSettingsComplex.vue")),
	TQueue: defineAsyncComponent(() => import("./child/TQueue.vue")),
	TKeypad: defineAsyncComponent(() => import("./child/TKeypad.vue")),
	TWizard: defineAsyncComponent(() => import("./child/TWizard.vue")),
	TAltRoute: defineAsyncComponent(() => import("./child/TAltRoute.vue")),
	TSms: defineAsyncComponent(() => import("./child/TSms.vue")),
	TRouting: defineAsyncComponent(() => import("./child/TRouting.vue")),
	TLog: defineAsyncComponent(() => import("./child/TLog.vue")),
	TDocument: defineAsyncComponent(() => import("./child/TDocument.vue")),
	TDocumentDetails: defineAsyncComponent(() => import("./child/TDocumentDetails.vue")),
	TDocumentAdd: defineAsyncComponent(() => import("./child/TDocumentAdd.vue")),
	TDocumentDelete: defineAsyncComponent(() => import("./child/TDocumentDelete.vue")),
	TFolder: defineAsyncComponent(() => import("./child/TFolder.vue")),
	TFolders: defineAsyncComponent(() => import("./child/TFolders.vue")),
	TFolderAdd: defineAsyncComponent(() => import("./child/TFolderAdd.vue")),
	TDashboard: defineAsyncComponent(() => import("./child/TDashboard.vue")),
	THourglass: defineAsyncComponent(() => import("./child/THourglass.vue")),
	THourglassRunning: defineAsyncComponent(() => import("./child/THourglassRunning.vue")),
	THourglassFinished: defineAsyncComponent(() => import("./child/THourglassFinished.vue")),
	TPaperclip: defineAsyncComponent(() => import("./child/TPaperclip.vue")),
	TCookie: defineAsyncComponent(() => import("./child/TCookie.vue")),
	TThumbsUpOutline: defineAsyncComponent(() => import("./child/TThumbsUpOutline.vue")),
	TThumbsUpFilled: defineAsyncComponent(() => import("./child/TThumbsUpFilled.vue")),
	TThumbsDownFilled: defineAsyncComponent(() => import("./child/TThumbsDownFilled.vue")),
	TThumbsDownOutline: defineAsyncComponent(() => import("./child/TThumbsDownOutline.vue")),
	TStorage: defineAsyncComponent(() => import("./child/TStorage.vue")),
	TBookmarks: defineAsyncComponent(() => import("./child/TBookmarks.vue")),
	TCheckboxUnchecked: defineAsyncComponent(() => import("./child/TCheckboxUnchecked.vue")),
	TRadioButtonUnchecked: defineAsyncComponent(() => import("./child/TRadioButtonUnchecked.vue")),
	TRadioButtonChecked: defineAsyncComponent(() => import("./child/TRadioButtonChecked.vue")),
	TNotificationFilled: defineAsyncComponent(() => import("./child/TNotificationFilled.vue")),
	TTrashcanOpen: defineAsyncComponent(() => import("./child/TTrashcanOpen.vue")),
	TDownloadProgressCircle: defineAsyncComponent(() => import("./child/TDownloadProgressCircle.vue")),
	TDownloadCircle: defineAsyncComponent(() => import("./child/TDownloadCircle.vue")),
	TDownloadCompleteTray: defineAsyncComponent(() => import("./child/TDownloadCompleteTray.vue")),
	TApps: defineAsyncComponent(() => import("./child/TApps.vue")),
	TCheckboxChecked: defineAsyncComponent(() => import("./child/TCheckboxChecked.vue")),
	TKey: defineAsyncComponent(() => import("./child/TKey.vue")),
	TBookmark: defineAsyncComponent(() => import("./child/TBookmark.vue")),
	TInfinity: defineAsyncComponent(() => import("./child/TInfinity.vue")),
	TFloppy: defineAsyncComponent(() => import("./child/TFloppy.vue")),
	TCatchUp: defineAsyncComponent(() => import("./child/TCatchUp.vue")),
	TStartOver: defineAsyncComponent(() => import("./child/TStartOver.vue")),
	TClosePanelLeft: defineAsyncComponent(() => import("./child/TClosePanelLeft.vue")),
	TClosePanelRight: defineAsyncComponent(() => import("./child/TClosePanelRight.vue")),
	TCopy: defineAsyncComponent(() => import("./child/TCopy.vue")),
	TPaste: defineAsyncComponent(() => import("./child/TPaste.vue")),
	TPause: defineAsyncComponent(() => import("./child/TPause.vue")),
	TPauseCircleOutline: defineAsyncComponent(() => import("./child/TPauseCircleOutline.vue")),
	TPlay: defineAsyncComponent(() => import("./child/TPlay.vue")),
	TPlayFilled: defineAsyncComponent(() => import("./child/TPlayFilled.vue")),
	TPlayCircleOutline: defineAsyncComponent(() => import("./child/TPlayCircleOutline.vue")),
	TMaximise: defineAsyncComponent(() => import("./child/TMaximise.vue")),
	TMinimise: defineAsyncComponent(() => import("./child/TMinimise.vue")),
	TFullScreen: defineAsyncComponent(() => import("./child/TFullScreen.vue")),
	TMicrophoneOn: defineAsyncComponent(() => import("./child/TMicrophoneOn.vue")),
	TMicrophoneOff: defineAsyncComponent(() => import("./child/TMicrophoneOff.vue")),
	TSpeakerOn: defineAsyncComponent(() => import("./child/TSpeakerOn.vue")),
	TSpeakerOff: defineAsyncComponent(() => import("./child/TSpeakerOff.vue")),
	TCallOff: defineAsyncComponent(() => import("./child/TCallOff.vue")),
	TCallInUse: defineAsyncComponent(() => import("./child/TCallInUse.vue")),
	TCallOutgoing: defineAsyncComponent(() => import("./child/TCallOutgoing.vue")),
	TCallIncoming: defineAsyncComponent(() => import("./child/TCallIncoming.vue")),
	TCallForwarded: defineAsyncComponent(() => import("./child/TCallForwarded.vue")),
	TCallMissed: defineAsyncComponent(() => import("./child/TCallMissed.vue")),
	TCallOffFilled: defineAsyncComponent(() => import("./child/TCallOffFilled.vue")),
	TCallInUseFilled: defineAsyncComponent(() => import("./child/TCallInUseFilled.vue")),
	TCallOutgoingFilled: defineAsyncComponent(() => import("./child/TCallOutgoingFilled.vue")),
	TCallIncomingFilled: defineAsyncComponent(() => import("./child/TCallIncomingFilled.vue")),
	TCallForwardedFilled: defineAsyncComponent(() => import("./child/TCallForwardedFilled.vue")),
	TCallMissedFilled: defineAsyncComponent(() => import("./child/TCallMissedFilled.vue")),
	TCallPause: defineAsyncComponent(() => import("./child/TCallPause.vue")),
	TCallPauseFilled: defineAsyncComponent(() => import("./child/TCallPauseFilled.vue")),
	TDelete: defineAsyncComponent(() => import("./child/TDelete.vue")),
	TDeleteFilled: defineAsyncComponent(() => import("./child/TDeleteFilled.vue")),
	TCloudDownload: defineAsyncComponent(() => import("./child/TCloudDownload.vue")),
	TCloudUpload: defineAsyncComponent(() => import("./child/TCloudUpload.vue")),
	TSend: defineAsyncComponent(() => import("./child/TSend.vue")),
	TVideocameraOn: defineAsyncComponent(() => import("./child/TVideocameraOn.vue")),
	TFlagOutline: defineAsyncComponent(() => import("./child/TFlagOutline.vue")),
	TFlagFilled: defineAsyncComponent(() => import("./child/TFlagFilled.vue")),
	TZoomIn: defineAsyncComponent(() => import("./child/TZoomIn.vue")),
	TZoomOut: defineAsyncComponent(() => import("./child/TZoomOut.vue")),
	TOpeninnewWindow: defineAsyncComponent(() => import("./child/TOpeninnewWindow.vue")),
	TDragHandleHorisontal: defineAsyncComponent(() => import("./child/TDragHandleHorisontal.vue")),
	TDragHandleVertical: defineAsyncComponent(() => import("./child/TDragHandleVertical.vue")),
	TSplit: defineAsyncComponent(() => import("./child/TSplit.vue")),
	TCallEnd: defineAsyncComponent(() => import("./child/TCallEnd.vue")),
	TLogIn: defineAsyncComponent(() => import("./child/TLogIn.vue")),
	TLogOut: defineAsyncComponent(() => import("./child/TLogOut.vue")),
	TUndo: defineAsyncComponent(() => import("./child/TUndo.vue")),
	TRedo: defineAsyncComponent(() => import("./child/TRedo.vue")),
	TOpenPanelLeft: defineAsyncComponent(() => import("./child/TOpenPanelLeft.vue")),
	TOpenPanelRight: defineAsyncComponent(() => import("./child/TOpenPanelRight.vue")),
	TSpaceBar: defineAsyncComponent(() => import("./child/TSpaceBar.vue")),
	TBackspaceFilled: defineAsyncComponent(() => import("./child/TBackspaceFilled.vue")),
	TBackspace: defineAsyncComponent(() => import("./child/TBackspace.vue")),
	TPictureInPictureIn: defineAsyncComponent(() => import("./child/TPictureInPictureIn.vue")),
	TPictureinPictureOut: defineAsyncComponent(() => import("./child/TPictureinPictureOut.vue")),
	TResumeWatching: defineAsyncComponent(() => import("./child/TResumeWatching.vue")),
	TSkiptoPrevious: defineAsyncComponent(() => import("./child/TSkiptoPrevious.vue")),
	TSkiptoNext: defineAsyncComponent(() => import("./child/TSkiptoNext.vue")),
	TRec: defineAsyncComponent(() => import("./child/TRec.vue")),
	TStop: defineAsyncComponent(() => import("./child/TStop.vue")),
	TPlayOnBox: defineAsyncComponent(() => import("./child/TPlayOnBox.vue")),
	TPlayOnFetch: defineAsyncComponent(() => import("./child/TPlayOnFetch.vue")),
	TSoundWave: defineAsyncComponent(() => import("./child/TSoundWave.vue")),
	TSeriesArchive: defineAsyncComponent(() => import("./child/TSeriesArchive.vue")),
	TTvGuide: defineAsyncComponent(() => import("./child/TTvGuide.vue")),
	TVideoOnDemand: defineAsyncComponent(() => import("./child/TVideoOnDemand.vue")),
	TSubtitles: defineAsyncComponent(() => import("./child/TSubtitles.vue")),
	TTvArchive: defineAsyncComponent(() => import("./child/TTvArchive.vue")),
	TMovieArchive: defineAsyncComponent(() => import("./child/TMovieArchive.vue")),
	TDownloadToBox: defineAsyncComponent(() => import("./child/TDownloadToBox.vue")),
	TPauseLarge: defineAsyncComponent(() => import("./child/TPauseLarge.vue")),
	TPlayLarge: defineAsyncComponent(() => import("./child/TPlayLarge.vue")),
	TSortFilled: defineAsyncComponent(() => import("./child/TSortFilled.vue")),
	TSortOutline: defineAsyncComponent(() => import("./child/TSortOutline.vue")),
	THardOfHearing: defineAsyncComponent(() => import("./child/THardOfHearing.vue")),
	TInfoStandalone: defineAsyncComponent(() => import("./child/TInfoStandalone.vue")),
	TShareContent: defineAsyncComponent(() => import("./child/TShareContent.vue")),
	TWarningCircleFilled: defineAsyncComponent(() => import("./child/TWarningCircleFilled.vue")),
	TWarningCircleOutline: defineAsyncComponent(() => import("./child/TWarningCircleOutline.vue")),
	TInfoCircleFilled: defineAsyncComponent(() => import("./child/TInfoCircleFilled.vue")),
	TInfoCircleOutline: defineAsyncComponent(() => import("./child/TInfoCircleOutline.vue")),
	TCriticalFilled: defineAsyncComponent(() => import("./child/TCriticalFilled.vue")),
	TCriticalOutline: defineAsyncComponent(() => import("./child/TCriticalOutline.vue")),
	TSuccessCircleFilled: defineAsyncComponent(() => import("./child/TSuccessCircleFilled.vue")),
	TSuccessCircleOutline: defineAsyncComponent(() => import("./child/TSuccessCircleOutline.vue")),
	TSuccess: defineAsyncComponent(() => import("./child/TSuccess.vue")),
	TPendingCircleFilled: defineAsyncComponent(() => import("./child/TPendingCircleFilled.vue")),
	TPendingCircleOutline: defineAsyncComponent(() => import("./child/TPendingCircleOutline.vue")),
	TErrorCircleFilled: defineAsyncComponent(() => import("./child/TErrorCircleFilled.vue")),
	TAttentionCircleFilled: defineAsyncComponent(() => import("./child/TAttentionCircleFilled.vue")),
	TPhoneMobile: defineAsyncComponent(() => import("./child/TPhoneMobile.vue")),
	TDevices: defineAsyncComponent(() => import("./child/TDevices.vue")),
	TWatch: defineAsyncComponent(() => import("./child/TWatch.vue")),
	TBatteryEmpty: defineAsyncComponent(() => import("./child/TBatteryEmpty.vue")),
	TBatteryFull: defineAsyncComponent(() => import("./child/TBatteryFull.vue")),
	TBatteryHalf: defineAsyncComponent(() => import("./child/TBatteryHalf.vue")),
	TBatteryQuarter: defineAsyncComponent(() => import("./child/TBatteryQuarter.vue")),
	TBatteryLow: defineAsyncComponent(() => import("./child/TBatteryLow.vue")),
	TSim: defineAsyncComponent(() => import("./child/TSim.vue")),
	TIoTSim: defineAsyncComponent(() => import("./child/TIoTSim.vue")),
	TWiFi: defineAsyncComponent(() => import("./child/TWiFi.vue")),
	TRouter: defineAsyncComponent(() => import("./child/TRouter.vue")),
	TLan: defineAsyncComponent(() => import("./child/TLan.vue")),
	TPhoneLandline: defineAsyncComponent(() => import("./child/TPhoneLandline.vue")),
	TPhoneReceiver: defineAsyncComponent(() => import("./child/TPhoneReceiver.vue")),
	TBatteryCharging: defineAsyncComponent(() => import("./child/TBatteryCharging.vue")),
	TPhoneReceiverFilled: defineAsyncComponent(() => import("./child/TPhoneReceiverFilled.vue")),
	TCamera: defineAsyncComponent(() => import("./child/TCamera.vue")),
	TAntenna: defineAsyncComponent(() => import("./child/TAntenna.vue")),
	TMonitor: defineAsyncComponent(() => import("./child/TMonitor.vue")),
	TTv: defineAsyncComponent(() => import("./child/TTv.vue")),
	TLaptop: defineAsyncComponent(() => import("./child/TLaptop.vue")),
	TConnected: defineAsyncComponent(() => import("./child/TConnected.vue")),
	TEthernet: defineAsyncComponent(() => import("./child/TEthernet.vue")),
	TTweBox: defineAsyncComponent(() => import("./child/TTweBox.vue")),
	TRadio: defineAsyncComponent(() => import("./child/TRadio.vue")),
	TESim: defineAsyncComponent(() => import("./child/TESim.vue")),
	TNum60DayFreeReturns: defineAsyncComponent(() => import("./child/TNum60DayFreeReturns.vue")),
	TExpressDelivery: defineAsyncComponent(() => import("./child/TExpressDelivery.vue")),
	TFreeDeliveryAndReturns: defineAsyncComponent(() => import("./child/TFreeDeliveryAndReturns.vue")),
	TPickUp: defineAsyncComponent(() => import("./child/TPickUp.vue")),
	TNoBinding: defineAsyncComponent(() => import("./child/TNoBinding.vue")),
};

const props = defineProps({
	size: {
		type: String,
		default: "m",
		validator: function (value) {
			return ["s", "m", "l", "xl", "2xl"].includes(value.toLowerCase());
		},
	},
	color: {
		type: String,
		default: "currentColor",
	},
	name: {
		type: String,
	},
});

const hw = computed(() => {
	switch (props.size) {
		case "s":
			return "16px";
		case "l":
			return "32px";
		case "xl":
			return "40px";
		case "2xl":
			return "48px";
		case "m":
		default:
			return "24px";
	}
});

const icon = computed(() => {
	if (!props.name) return null;
	const key = Object.keys(components).find(
		(key) => key.toLowerCase() === `t${props.name.replace(/-/g, "").toLowerCase()}`,
	);
	if (key) return components[key];
	return null;
});

//test
</script>
